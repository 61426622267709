<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="handleSubmit" action="#">
          <h4> Add FAQ </h4>
          <div class="form-group">
            <label for="question"> Question </label>
            <input type="text" id="question" class="form-control" placeholder="Enter question here"
                   v-model="form.question"
            required
            >
          </div>
          <div class="form-group">
            <label for="question"> Answer </label>
            <textarea name="" id="" cols="30" rows="10" placeholder="" class="form-control"
                      v-model="form.answer"
            required
            ></textarea>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary px-2">Save FAQ</button>
          </div>

        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div role="tablist" class="">

          <b-card no-body class="mb-1" v-for="faq in faqs" :key="faq['id']">
            <b-card-header header-tag="header" role="tab">
              <h5 class="m-0">
                <a
                    v-b-toggle.customaccordion-1
                    class="text-dark"
                    href="javascript: void(0);"
                >
                  <i class="mdi mdi-help-circle mr-1 text-primary"></i>
                  {{faq.question}}
                </a>
                <a href="#" class="text-danger ml-2" @click.prevent="remove(faq.id)" > delete </a>
              </h5>
            </b-card-header>
            <b-collapse
                id="customaccordion-1"
                visible
                accordion="accordion"
                role="tabpanel"
            >
              <b-card-body>
                <b-card-text>{{faq.answer}}</b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from "../../../../../app.config.json";

/**
 * Starter component
 */
export default {
  head() {
    return {
      title: `${this.title}`,
    };
  },
  data() {
    return {
      form: {
        question: '',
        answer: ''
      },
      title: "Starter",
      text: `
         Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.
        `,
      content: `Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt.Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim.`,
    };
  },
  computed: {
    faqs(){
      return this.$store.getters.GET_FAQS;
    }
  },
  methods:{
    handleSubmit(){
      this.$store.dispatch('createFaq', this.form).then(() => {
        this.form.question = '';
        this.form.answer = ''
      })
    },
    remove(id){
      this.$store.dispatch('removeFaq', id)
    }
  },
  created() {
    this.$store.dispatch('getFaqs')
    this.$store.dispatch('setPageTitle', 'F.A.Q')
  }
};
</script>
